<template>
  <v-container fluid>
    <v-row class="ma-3" wrap>
      <!-- Descrição -->
      <v-col cols="12">
        <v-text-field
          v-model="tipoVaga.descricao"
          ref="descricao"
          label="Descrição"
          placeholder="Digite a descrição do tipo de vaga"
          dense
          outlined
          data-vv-as="descrição"
          persistent-hint
          :hint="errors.first('res_tipo_vaga.descricao')"
          :error="
            errors.collect('res_tipo_vaga.descricao').length ? true : false
          "
          v-validate="'required|min:3|max:150'"
          data-vv-scope="res_tipo_vaga"
          data-vv-validate-on="change"
          id="descricao"
          name="descricao"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TipoVagaForm",
  inject: ["$validator"],
  computed: {
    tipoVaga: {
      get() {
        return this.$store.getters["resTipoVagas/getTipoVaga"];
      },
      set(value) {
        this.$store.dispatch("resTipoVagas/setTipoVaga", value);
      },
    },
  },
};
</script>

<style></style>
